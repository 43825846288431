import { useAnalyticsScript } from './useAnalyticsScript'

interface Events {
  login: { userId: number }
  registration: { externalId: string }
}

export function useAnalytics() {
  const { onLoaded } = useAnalyticsScript()
  /**
   * Отправка события в Matomo Tag Manager
   * @param eventName - Имя события из интерфейса Events
   * @param payload - Данные события
   */
  function sendEvent<K extends keyof Events>(eventName: K, payload: Events[K]) {
    onLoaded((mtm) => {
      mtm.push({
        event: eventName,
        ...payload,
      })
    })
  }

  return { sendEvent }
}
